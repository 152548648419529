import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { servicesList } from "../../constants/services";
import Menu from "../../components/Menu";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom/dist";
import banner from "../../assets/img/service-detail-banner.webp";
import phoneIcon from "../../assets/img/icon-phone.svg";
import emailIcon from "../../assets/img/icon-email.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Footer from "../../components/Footer";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import imgIntro from "../../assets/img/detail-ai-intro.jpg";
import AIImgIntro from "../../assets/img/ai-product-image.webp";
import CVImgIntro from "../../assets/img/cv-product-image.png";
import DataImgIntro from "../../assets/img/data-product-image.jpg";
import ChatbotImgIntro from "../../assets/img/chatbot-product-image.jpg";
import imgDetail from "../../assets/img/detail-ai.jpg";
import ServiceTag from "../../components/ServiceTag";
import HighlightContent from "../../components/HighlightContent";
import bgPage from "../../assets/img/bg-page-detail.jpg";
import { Image, Modal } from "antd";
import Whatsapp from "./../../assets/img/icon/whatsapp.svg";
import WhatsappQr from "../../assets/img/whatsapp-qr.png";
import "./styles.css";
export default function ServiceDetail() {
  const { t } = useTranslation();
  const params = useParams();
  const listServiceTitle = [
    {
      img: AIImgIntro,
      title: t("ai_service_title"),
      link: "aivision-platform",
      description: t("ai_service_description"),
      caseStudy: [
        {
          title: t("ai_retail_title"),
          description: [
            t("ai_retail_description_1"),
            t("ai_retail_description_2"),
          ],
        },
        {
          title: t("ai_monitoring_title"),
          description: [
            t("ai_monitoring_description_1"),
            t("ai_monitoring_description_2"),
          ],
        },
        {
          title: t("ai_competition_title"),
          description: [
            t("ai_competition_description_1"),
            t("ai_competition_description_2"),
          ],
        },
      ],
    },
    {
      title: t("chatbot_service_title"),
      link: "chatbot",
      description: t("chatbot_service_description"),
      img: ChatbotImgIntro,
      caseStudy: [
        {
          title: t("chatbot_customer_title"),
          description: [t("chatbot_customer_description")],
        },
        {
          title: t("chatbot_queries_title"),
          description: [
            t("chatbot_queries_description_1"),
            t("chatbot_queries_description_2"),
          ],
        },
        {
          title: t("chatbot_recommendation_title"),
          description: [t("chatbot_recommendation_description")],
        },
      ],
    },
    {
      title: t("data_analytics_service_title"),
      link: "data-analytics-platform",
      description: t("data_analytics_service_description"),
      img: DataImgIntro,
      caseStudy: [
        {
          title: t("data_platform_title"),
          description: [t("data_platform_description")],
        },
        {
          title: t("data_inventory_title"),
          description: [t("data_inventory_description")],
        },
        {
          title: t("data_social_title"),
          description: [
            t("data_social_description_1"),
            t("data_social_description_2"),
          ],
        },
      ],
    },
    {
      title: t("smart_hr_service_title"),
      link: "smart-hr-platform",
      description: t("smart_hr_service_description"),
      img: CVImgIntro,
      caseStudy: [
        {
          title: t("hr_extraction_title"),
          description: [
            t("hr_extraction_description_1"),
            t("hr_extraction_description_2"),
          ],
        },
        {
          title: t("hr_interview_title"),
          description: [
            t("hr_interview_description_1"),
            t("hr_interview_description_2"),
          ],
        },
        {
          title: t("hr_job_title"),
          description: [t("hr_job_description_1"), t("hr_job_description_2")],
        },
      ],
    },
  ];

  const { serviceSlug, user } = useParams();
  console.log(serviceSlug);
  console.log(listServiceTitle[0].link);
  const currentService = listServiceTitle.find(
    (service) => service.link === serviceSlug
  );
  console.log(currentService);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({
          behavior: "smooth", // Cuộn mượt mà
        });
      }
    }, 500); // Thời gian chờ 500ms

    // Dọn dẹp timer khi component unmount
    return () => clearTimeout(timer);
  }, [params]);
  return (
    <>
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${banner})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 600,
          marginBottom: "50px",
          "@media (max-width: 500px)": {
            height: 300,
            marginBottom: "15px",
          },
        }}
      >
        <Grid container height="inherit">
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"end"}
            alignItems={"center"}
            mb="60px"
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"10px 20px"}
              sx={{
                background: "#d61f23",
                color: "white",
                fontSize: "30px",
                textTransform: "uppercase",
                marginBottom: "15px",
              }}
            >
              <Typography
                onClick={() => navigate("/services")}
                sx={{ cursor: "pointer" }}
              >
                {t("services_page_title")}
              </Typography>
              <ChevronRightIcon />
              <Typography>{t("detail_btn")}</Typography>
            </Box>
            <div ref={targetRef} />
            <Typography className="text-banner">
              {t("services_page_title") + " " + t("detail_btn")}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${bgPage})`,
            backgroundPosition: "120% 50%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "800px",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: "-1",
            filter: "blur(0.8px)",
          }}
        />
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            maxWidth: "1000px",
            justifyContent: "center",
          }}
        >
          {/* Left side bar */}
          <Grid
            item
            md={4}
            sx={{
              "@media (max-width: 500px)": {
                display: "none",
              },
            }}
          >
            {listServiceTitle?.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#edf1f2",
                    padding: "10px 20px",
                    marginBottom: "10px",
                    cursor: "pointer",
                    "&:hover": {
                      color: "white",
                      background: "#d61f23",
                    },
                  }}
                  className={item.link === serviceSlug ? "service-active" : ""}
                  onClick={() => navigate(`/services/detail/${item.link}`)}
                >
                  <Typography>{item.title}</Typography>
                  <ArrowRightAltIcon />
                </Box>
              );
            })}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                background: "#edf1f2",
                padding: "10px 20px",
                marginBottom: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                {t("need_help")}
              </Typography>
              {/* <Typography>Description</Typography> */}
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                <img
                  src={phoneIcon}
                  alt="phone"
                  style={{
                    width: "26px",
                    marginRight: "10px",
                    marginLeft: "5px",
                  }}
                />
                <Typography>​0981419967</Typography>
              </Box>
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                <img
                  src={emailIcon}
                  alt="email"
                  style={{ width: "30px", marginRight: "10px" }}
                />
                <Typography>info@aivgroups.com​</Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                component={"div"}
                onClick={handleOpen}
                style={{ cursor: "pointer" }}
              >
                <div>
                  <Image
                    src={Whatsapp}
                    alt="Whatsapp"
                    style={{ width: "32px", height: "32px" }}
                    preview={{ src: WhatsappQr }}
                  />
                </div>
                <Typography ml={"8px"}>Whatsapp</Typography>
              </Box>
              <Modal
                open={visible}
                onCancel={handleClose}
                footer={null}
                closeIcon={null}
                style={{
                  width: "fit-content",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Image src={WhatsappQr} alt="Whatsapp QR Code" />
              </Modal>
            </Box>
          </Grid>

          {/* End left side bar */}
          {/* Main content */}

          {currentService && (
            <Grid
              item
              md={8}
              xs={12}
              sx={{
                textAlign: "center",
                "@media (max-width: 500px)": {
                  margin: "10px",
                },
              }}
            >
              <img src={currentService.img} alt="image" style={{}} />
              <Typography sx={{ fontSize: "30px", margin: "20px 0" }}>
                {currentService.title}
              </Typography>
              <div
                style={{
                  marginBottom: "20px",
                  textAlign: "justify",
                  lineHeight: "30px",
                }}
                dangerouslySetInnerHTML={{
                  __html: currentService.description,
                }}
              />

              {currentService.caseStudy.map((item, index) => {
                return (
                  <Accordion
                    key={index}
                    TransitionProps={{ unmountOnExit: true }}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item.description.map((content, idx) => {
                        return (
                          <Typography key={idx} sx={{ textAlign: "justify" }}>
                            {content}
                          </Typography>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          )}

          {/* End main content */}
        </Grid>
      </Box>

      <Footer />
    </>
  );
}
