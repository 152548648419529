import { axiosClient } from "utils/axiosClient";

export const adminApi = {
  getAllBlogs(page, limit) {
    return axiosClient.get("/blogs/", {
      params: {
        page,
        limit,
      },
    });
  },
  getBlogBySlug(slug) {
    return axiosClient.get(`/blogs/${slug}`);
  },
  createBlog(data) {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("en_title", data.en_title);
    formData.append("en_content", data.en_content);
    data.image && formData.append("banner", data.image[0].file);
    return axiosClient.post("/blogs/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateBlog(slug, data) {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("en_title", data.en_title);
    formData.append("en_content", data.en_content);
    data.image && formData.append("banner", data.image[0].file);
    return axiosClient.put(`/blogs/${slug}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteBlog(post_id) {
    return axiosClient.delete(`blogs/${post_id}`);
  },
  deleteBlogImage(image_name) {
    return axiosClient.delete(`posts/images/${image_name}`);
  },
};
