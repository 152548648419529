export default {
  /*** home page ***/
  home_page_title: "Trang chủ",
  banner_title_1: "Đối tác cung cấp Giải pháp",
  banner_title_2: "Trí tuệ nhân tạo",
  detail_btn: "Chi tiết",
  title_offer: "Giải pháp của chúng tôi",
  explore_text: "Khám phá các Giải pháp Công nghệ hiện đại của AIVision",
  read_time: "phút đọc",
  related_blogs: "Bài viết liên quan",
  /*** about page ***/
  about_page_title: "Giới thiệu",
  about_aivision: "VỀ CÔNG TY AIVISION",
  about_page_description:
    "Chúng tôi là AIVision! Thuộc thế hệ đi đầu trong việc áp dụng công nghệ Trí tuệ nhân tạo vào ngành bán lẻ và siêu thị tại Việt Nam. Với tầm nhìn là cung cấp các giải pháp đổi mới mang lại giá trị cho khách hàng, AIVision đã phát triển các sản phẩm tiên tiến nổi bật hơn so với các đối thủ. Hệ thống thị giác Trí tuệ nhân tạo đã giúp cho các nhà bán lẻ nắm bắt được hành vi và sở thích mua hàng của khách hàng. Bên cạnh đó, AIVision còn cung cấp Hệ thống Quản lý nguồn nhân lực thông minh sử dụng Trí tuệ nhân tạo để đánh giá ứng viên và đảm bảo phù hợp với công việc. Cùng với đó là Nền tảng Phân tích dữ liệu và dịch vụ Chatbot được tạo ra bởi đội ngũ chúng tôi gồm các chuyên gia là các tiến sĩ chuyên nghiên cứu và phát triển Trí tuệ nhân tạo. AIVision cam kết tận dụng sức mạnh của Trí tuệ nhân tạo để tạo ra nhiều cải tiến, mang lại giá trị cho khách hàng của chúng tôi.​​",

  our_team: "Đội ngũ AIVision",
  team_grid: "Đội ngũ lãnh đạo công ty",

  our_client: "Khách hàng của AIVision",
  client_grid: "Khách hàng",

  /*** services page ***/
  services_page_title: "Dịch vụ",
  discover_services_title: "Cùng khám phá Dịch vụ của chúng tôi!",
  discover_services_description:
    "Công nghệ Trí tuệ nhân tạo bao gồm các công cụ và kỹ thuật sử dụng thuật toán Trí tuệ nhân tạo và mô hình Học máy để xử lý và phân tích dữ liệu. Tại AIVision, chúng tôi hiện đang cung cấp bốn dịch vụ chính.​",
  read_more: "Đọc thêm",
  hidden: "Ẩn",
  need_help: "Cần Hỗ Trợ?",

  /*** blog page ***/
  blog_page_title: "Blog",
  /*** contact page ***/
  contact_page_title: "Liên hệ",

  /*** contact component ***/
  contact_box_description: "Hãy cho chúng tôi biết câu chuyện của bạn!",
  contact_box_title: "Liên hệ với chúng tôi",
  contact_box_name: "Tên",
  contact_box_email: "Thư điện tử",
  contact_box_message: "Tin nhắn",
  send: "Gửi",
  information: "Thông tin liên hệ",

  /*** footer component ***/
  about_company: "Công ty",
  footer_about_company_description:
    "AIVision tận dụng tiềm năng phát triển của Trí tuệ nhân tạo, tạo ra các giải pháp đổi mới giúp phát triển doanh nghiệp, tăng hiệu suất và mang lại thật nhiều giá trị, đồng thời thúc đẩy các giải pháp Trí tuệ nhân tạo có đạo đức và có trách nhiệm.​",
  quick_link: "Liên kết",
  our_services: "Dịch vụ",
  our_faq: "​​​​​FAQ’s​",
  meet_team: "Đội ngũ Công ty",
  news_media: "Tin tức và Truyền thông",
  contact_us: "Liên hệ",
  newsletter: "Tin tức và Truyền thông",
  newsletter_description:
    "Tại AIVision, chúng tôi liên tục đổi mới các ứng dụng và công nghệ Trí tuệ nhân tạo mới để mang đến nhiều giá trị nhất cho cuộc sống của chúng ta. Chúng tôi tin rằng Trí tuệ nhân tạo có tiềm năng để thay đổi cách chúng ta sống và làm việc, và chúng tôi rất háo hức được trở thành một phần của hành trình này.​",

  /*** 4 services ***/
  ai_service_title: "Nền tảng Chấm điểm gian hàng",
  ai_service_description:
    "Hệ thống đánh giá cho việc trưng bày, đếm các danh mục sản phẩm của công ty, cung cấp thông tin thị phần, hình ảnh và sản phẩm cùng loại để đưa ra các chiến lược kinh doanh. Ngoài ra còn có thể tạo hình ảnh ghép thực tế bằng Trí tuệ nhân tạo.​<br/>Các chức năng:​<br/>- Nhận diện và đếm chính xác các sản phẩm được trưng bày trên kệ.​<br/>- Ghi nhận chính xác giá cả và các chương trình khuyến mãi.​<br/>- Thu thập thông tin quan trọng về các sản phẩm mục tiêu và đối thủ cạnh tranh.​<br/>- Xử lý theo thời gian thực.​<br/>- Hiệu suất cao​",

  chatbot_service_title: "Chatbot",
  chatbot_service_description:
    "Chatbot là một công nghệ tích hợp các thuật toán được ứng dụng trong giao tiếp nhằm chia sẻ, tương tác công việc, xử lý trực tiếp và trực tuyến thông minh bằng sự hỗ trợ của Trí tuệ nhân tạo, đảm bảo bảo mật cao trong giao tiếp kinh doanh và các ứng dụng mới khác theo yêu cầu khách hàng.​<br/>Các chức năng:​<br/>- Hỗ trợ khách hàng 24/7.​<br/>- Phản hồi chính xác và nhanh chóng.​<br/>- Tự động đặt chỗ trước.​<br/>- Truy xuất thông tin từ khách hàng.​<br/>- Hỗ trợ bất kỳ ngôn ngữ nào.<br/>- Đào tạo và cung cấp câu trả lời liên quan đến doanh nghiệp.",

  smart_hr_service_title: "Nền tảng Hỗ trợ tuyển dụng",
  smart_hr_service_description:
    "Một nền tảng được hỗ trợ bởi Trí tuệ nhân tạo, có khả năng lọc đơn tìm việc và cung cấp kết quả về ứng viên phù hợp cho các công việc khác nhau trong quá trình tuyển dụng, cho phép chỉnh sửa các biểu mẫu đơn xin việc theo các mẫu mới và lưu trữ mọi thông tin một cách khoa học trong ngân hàng dữ liệu Nhân sự bằng Trí tuệ nhân tạo. ​<br/>Các chức năng:​<br/>- Trích xuất thông tin CV.​<br/>- Phân tích mô tả công việc.​<br/>- Xếp hạng ứng viên dựa trên mô tả công việc và các quy tắc tùy chỉnh.​<br/>- Gợi ý của Trí tuệ nhân tạo.<br/>- Tích hợp API.​",

  data_analytics_service_title: "Nền tảng Phân tích dữ liệu",
  data_analytics_service_description:
    "Hệ thống Phân tích Dữ liệu Thông minh tạo ra các kết quả chính xác thông qua Trí tuệ nhân tạo và Công nghệ.​<br/>Các chức năng:​<br/>- Thu thập thông tin từ dữ liệu.​<br/>- Dữ liệu có thể mở rộng và có khả năng “tự học”.​<br/>- Cung cấp báo cáo phân tích và đề xuất từ nhóm nhà khoa học dữ liệu.​<br/>- Cung cấp báo cáo phân tích và đề xuất từ nhóm nhà khoa học dữ liệu.​<br/>- Nền tảng ETL sử dụng hệ thống các quy tắc kinh doanh để làm sạch và sắp xếp dữ liệu thô, sau đó chuẩn bị dữ liệu cho quá trình lưu trữ, phân tích và máy học.​<br/>- Dashboard dữ liệu có sẵn trên web và thiết bị di động.​",

  /********* case study ***********/
  /********* ai ***********/
  ai_retail_title: "Trí tuệ nhân tạo trong ngành hàng bán lẻ",
  ai_retail_description_1:
    "Các nhà bán lẻ đã sử dụng công nghệ Thị giác Trí tuệ nhân tạo (AI) để tối ưu hóa bố trí cửa hàng và cải thiện trải nghiệm khách hàng. Vấn đề các chủ cửa hàng gặp phải là lượng khách thấp và doanh số giảm, và đang tìm cách thu hút nhiều khách hàng hơn nhằm tăng doanh thu.",
  ai_retail_description_2:
    "Khi áp dụng nền tảng AI Vision của chúng tôi đã xác định được những khách hàng thường dừng lại để nhìn vào một sản phẩm cụ thể, nhưng sản phẩm đó không được trưng bày một cách nổi bật. Vì vậy, chủ cửa hàng đã di chuyển các sản phẩm đó đến vị trí dễ nhìn hơn, tạo ra sự tăng trưởng đáng kể trong doanh số bán hàng.",

  ai_monitoring_title: "Nền tảng AIVision trong Quản lý Kệ hàng",
  ai_monitoring_description_1:
    "Một chuỗi cửa hàng tạp hóa muốn cải thiện quy trình quản lý kệ hàng để đảm bảo sản phẩm luôn có sẵn và được trưng bày đúng cách. Họ đã gặp khó khăn với việc hết hàng và vị trí trưng bày sản phẩm không đúng, dẫn đến mất doanh số và khách hàng không hài lòng.",
  ai_monitoring_description_2:
    "Chuỗi cửa hàng đã triển khai một hệ thống Thị giác Trí tuệ nhân tạo (AI) sử dụng thuật toán Học máy để phân tích hình ảnh của kệ hàng trong thời gian thực. Sau đó, ứng dụng Thị giác AI phân tích các hình ảnh đã chụp để xác định các sản phẩm hết hàng, sản phẩm bị đặt sai vị trí và các vấn đề khác.",

  ai_competition_title:
    "Ứng dụng Thị giác Trí tuệ nhân tạo trong Cạnh tranh sản phẩm",
  ai_competition_description_1:
    "Các nhà sản xuất hàng tiêu dùng muốn hiểu rõ hơn về cách sản phẩm của họ được trưng bày trong cửa hàng và hiệu suất của chúng so với đối thủ.",
  ai_competition_description_2:
    "Hệ thống của chúng tôi sẽ cung cấp thông tin về cách sản phẩm của nhà sản xuất được trưng bày so với đối thủ, cho phép cửa hàng tối ưu hóa việc đặt sản phẩm để từ đó doanh nghiệp có thể đưa ra được các chiến lược tiếp thị phù hợp.",

  /********* chatbot ***********/
  chatbot_customer_title: "Chatbot – Dịch vụ Khách hàng 24/7",
  chatbot_customer_description:
    "Nếu một doanh nghiệp cần cung cấp dịch vụ chăm sóc khách hàng 24/7, Chatbot là một lựa chọn đúng đắn. Chatbot có thể hỗ trợ ngay lập tức cho khách hàng, đối với các yêu cầu thông thường như thông tin tài khoản, trạng thái đơn hàng và chi tiết sản phẩm. Đối với các yêu cầu phức tạp hơn, Chatbot có thể chuyển tiếp yêu cầu đến một nhân viên chăm sóc khách hàng, đảm bảo khách hàng nhận được phản hồi kịp thời và chính xác.",

  chatbot_queries_title:
    "Áp dụng Chatbot trong việc xử lý lượng yêu cầu khách hàng cao",
  chatbot_queries_description_1:
    "Khi một doanh nghiệp nhận được số lượng lớn yêu cầu từ khách hàng, có thể khó khăn để đáp ứng mỗi yêu cầu một cách kịp thời và hiệu quả. Điều này có thể giảm sự hài lòng của khách hàng, dẫn đến mất doanh số.",
  chatbot_queries_description_2:
    "Trong trường hợp này nên triển khai Chatbot, có thể giúp doanh nghiệp tiết kiệm chi phí, thay vì thuê thêm nhân viên. Ngoài ra, Chatbot cũng có thể giảm công việc cho nhân viên chăm sóc khách hàng và tự động hóa các nhiệm vụ thông thường.",

  chatbot_recommendation_title: "Đưa ra các gợi ý cho người dùng",
  chatbot_recommendation_description:
    "Chatbot sử dụng thuật toán Học máy để phân tích dữ liệu người dùng và đưa ra các gợi ý về những sản phẩm hoặc dịch vụ mà người dùng có khả năng quan tâm nhất. Chatbot có thể sử dụng công nghệ Xử lý Ngôn ngữ Tự nhiên (NLP) để hiểu các yêu cầu của người dùng và cung cấp phản hồi liên quan, cũng như tính nhận thức ngữ cảnh để đưa ra gợi ý cá nhân hóa dựa trên các yếu tố như vị trí, thời tiết hoặc thời gian trong ngày.",

  /*********** data platform *****************/
  data_platform_title:
    "Ứng dụng nền tảng Phân tích dữ liệu nhằm cải thiện trải nghiệm khách hàng",
  data_platform_description:
    "Người bán phát hiện nhiều khách hàng đã bỏ giỏ hàng online của mình trước khi hoàn thành giao dịch mua hàng. Bằng cách phân tích số liệu trên nền tảng phân tích dữ liệu, đã xác định được những nguyên nhân dẫn đến việc bỏ giỏ hàng. Sau phân tích, kết quả cho thấy do chi phí vận chuyển cao và quy trình thanh toán phức tạp. Sau đó, các chủ cửa hàng online đã thực hiện các thay đổi trên trang web của mình để giải quyết những vấn đề này, giúp tăng đáng kể số giao dịch mua hàng hoàn thành.",

  data_inventory_title:
    "Xác định điểm tắt nghẽn, tối ưu hóa mức tồn kho và giảm chi phí",
  data_inventory_description:
    "Áp dụng nền tảng Phân tích dữ liệu để xác định các rào cản, tối ưu hóa mức tồn kho và giảm chi phí. Bất kể công ty sản xuất toàn cầu nào có chuỗi cung ứng phức tạp liên quan đến nhiều nhà cung cấp, kho và trung tâm phân phối, đều có thể áp dụng nền tảng Phân tích dữ liệu để giảm 20% chi phí tồn kho bằng cách tối ưu hóa mức tồn kho an toàn, giảm 30% tình trạng hết hàng thông qua việc cải thiện dự báo nhu cầu và kế hoạch tồn kho, v.v.",

  data_social_title: "Phân tích Dữ liệu trên các trang Mạng xã hội",
  data_social_description_1:
    "Các quản lý bán hàng đã ghi nhận sự giảm doanh số và giảm sự tương tác của khách hàng. Họ nhận thấy có những ý kiến và phản hồi tiêu cực về sản phẩm và dịch vụ của mình trên các nền tảng mạng xã hội và muốn hiểu nguyên nhân gốc rễ của những vấn đề này.",
  data_social_description_2:
    "Để giải quyết tình huống này, nền tảng Phân tích dữ liệu thực hiện phân tích nâng cao, bao gồm phân tích cảm xúc và mô hình hóa chủ đề, để xác định cảm xúc của khách hàng và các chủ đề đang trở nên phổ biến liên quan đến thương hiệu và sản phẩm của họ.",

  /*********** smart HR *****************/
  hr_extraction_title: "Trích xuất thông tin từ CV ứng viên",
  hr_extraction_description_1:
    "Bộ phận Nhân sự đối mặt với hàng loạt hồ sơ và muốn tiết kiệm một lượng đáng kể thời gian để trích xuất thông tin từ các hồ sơ ứng viên.",
  hr_extraction_description_2:
    "Trong trường hợp này, Nền tảng Nhân sự thông minh có thể sử dụng công nghệ phân tích hồ sơ để trích xuất thông tin từ Sơ yếu lý lịch được nộp bởi ứng viên. Phân tích hồ sơ bao gồm việc sử dụng thuật toán Học máy để trích xuất thông tin liên quan từ hồ sơ, như kinh nghiệm làm việc, quá trình học tập và kỹ năng, và lưu trữ nó dưới định dạng có cấu trúc để tiếp tục xử lý.",

  hr_interview_title: "Thu thập thông tin ứng viên trên các trang mạng xã hội",
  hr_interview_description_1:
    "Các thông tin trên mạng xã hội có thể bổ sung thêm dữ liệu về ứng viên ngoài những điều thường được đưa vào Sơ yếu lý lịch.",
  hr_interview_description_2:
    "Bằng cách trích xuất thông tin từ các bài viết trên mạng xã hội thông qua việc triển khai Nền tảng Nhân sự thông minh, phòng Nhân sự có thể thu thập được thêm thông tin về kỹ năng, sở thích và kinh nghiệm của ứng viên, giúp đánh giá sự phù hợp của họ với các vai trò cần tuyển dụng.",

  hr_job_title: "Phân tích Bản Mô tả công việc",
  hr_job_description_1:
    "Nếu công ty đang tìm kiếm sự hiệu quả, độ chính xác và cải thiện quy trình tuyển dụng, hãy triển khai Nền tảng Nhân sự thông minh của AIVision.",
  hr_job_description_2:
    "Bằng cách phân tích Mô tả công việc, Nền tảng Nhân sự thông minh có thể xác định các từ khóa, kỹ năng và yêu cầu liên quan nhất cần thiết cho một vai trò cụ thể. Điều này đảm bảo Bản Mô tả công việc phản ánh chính xác yêu cầu của vị trí, giúp phòng Nhân sự thu hút ứng viên sở hữu các kỹ năng và kinh nghiệm cần thiết.",

  /******** Blog Box ********/
  blog_1_title: "Nhìn vào tương lai với Trí tuệ nhân tạo",
  blog_1_description:
    "Công nghệ Trí tuệ nhân tạo tiên tiến của AIVision có thể giúp doanh nghiệp dự đoán xu hướng, nhận diện cơ hội và vượt qua đối thủ. Cho dù tổ chức bạn hoạt động trong ngành bán lẻ, tài chính hoặc bất kỳ ngành công nghiệp nào khác, AIVision có thể cung cấp các dịch vụ phù hợp, giúp các công ty đưa ra quyết định thông minh và phát triển kinh doanh nhanh chóng.",
  blog_1_date: "01-07-2023",

  blog_2_title:
    "Cải tiến các hệ thống vận hàng với Thị giác Trí tuệ nhân tạo AI Vision",
  blog_2_description:
    "Từ quản lý chuỗi cung ứng đến kiểm soát chất lượng, Nền tảng Thị giác Trí tuệ nhân tạo có thể giúp bạn tối ưu hoá hoạt động và cải thiện hiệu suất. Các công cụ được trang bị Trí tuệ nhân tạo của chúng tôi có thể phân tích dữ liệu trong thời gian thực, phát hiện các bất thường và đề xuất cải tiến, giúp bạn đưa ra quyết định dựa trên dữ liệu và giảm thiểu chi phí.",
  blog_2_date: "01-06-2023",

  blog_3_title:
    "Cải tiến quá trình tuyển dụng với Nền tảng Nhân sự thông minh của AIVision chúng tôi",
  blog_3_description:
    "​Nền tảng của chúng tôi sử dụng công nghệ tiên tiến để tối ưu hóa quy trình tuyển dụng và cải thiện hiệu suất. Từ quá trình tuyển dụng, quá trình đào tạo cho đến quản lý hiệu suất, nền tảng của chúng tôi có thể giúp bạn tiết kiệm thời gian và giảm chi phí, đồng thời cải thiện trải nghiệm của nhân viên.",
  blog_3_date: "01-05-2023",

  blog_4_title:
    "Mở khóa “sức mạnh” dữ liệu của bạn với nền tảng Phân tích dữ liệu của AIVision",
  blog_4_description:
    "Nền tảng của chúng tôi có thể giúp các doanh nghiệp chuyển đổi dữ liệu thành thông tin mang tính hành động. Với các công cụ phân tích mạnh mẽ và hệ thống Dashboard trực quan, doanh nghiệp của bạn có thể dễ dàng hiểu được các thông tin phức tạp, từ đó đưa ra quyết định dựa trên dữ liệu để thúc đẩy sự phát triển kinh doanh.",
  blog_4_date: "01-04-2023",

  blog_5_title:
    "Tối ưu hóa quy trình phân tích dữ liệu của bạn với nền tảng của AIVision",
  blog_5_description:
    "Với việc ứng dụng công nghệ khoa học dữ liệu, giúp các doanh nghiệp dễ dàng thu thập, quản lý và phân tích dữ liệu của mình tại một nơi duy nhất. Từ tích hợp dữ liệu đến mô hình hóa dữ liệu và trực quan hóa dữ liệu, nền tảng của chúng tôi cung cấp một quy trình phân tích dữ liệu liền mạch và hiệu quả.",
  blog_5_date: "01-03-2023",

  blog_6_title:
    "Tương tác với khách hàng của bạn 24/7 thông qua Chatbot – Mr. Aivision",
  blog_6_description:
    "Trong thế giới số hóa hiện đại ngày nay, việc tương tác với khách hàng và hỗ trợ kịp thời là rất quan trọng để các doanh nghiệp duy trì sự cạnh tranh. Với trợ lý ảo Mr. Aivision của chúng tôi, giúp tương tác hiệu quả với khách hàng 24/7, cung cấp cho sự hỗ trợ và thông tin ngay lập tức khi cần.",
  blog_6_date: "01-02-2023",
  alert_copied: "Đã sao chép đường dẫn!",
  services_detail_1: "nen-tang-cham-diem-gian-hang",
  services_detail_2: "chatbot",
  services_detail_3: "nen-tang-phan-tich-du-lieu",
  services_detail_4: "nen-tang-ho-tro-tuyen-dung"
};
