import { Box, Button, Divider, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { adminApi } from "../adminsApi";
import { BlogForm } from "../components/BlogForm";
import { resolver } from "../resolver";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { blogsAction, selectBlogContent } from "pages/Blogs/saga/blogSlice";
import { getValue } from "@testing-library/user-event/dist/utils";
import { IMAGE_URL_ROOT } from "utils/constant";

export const BlogEditPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);
  const dispatch = useDispatch();
  const blogContent = useSelector(selectBlogContent);
  const [bannerFile, setBannerFile] = useState();
  useEffect(() => {
    dispatch(blogsAction.getBlogDetail(id));
  }, [id, dispatch]);
  const initialValues = {
    title: "",
    content: "",
    image: [],
    en_title: "",
    en_content: "",
  };

  const {
    control,
    getValues,
    setValue,
    setFocus,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver,
    defaultValues: initialValues,
  });
  const urlToFileAndBase64 = async (url, filename) => {
    // Bắt đầu loading
    dispatch(blogsAction.setLoading(true));

    try {
      // Lấy dữ liệu từ URL
      const response = await fetch(url);
      console.log(url);
      console.log(response);
      // Kiểm tra phản hồi
      if (!response.ok) throw new Error("Network response was not ok.");

      // Chuyển đổi phản hồi thành blob
      const blob = await response.blob();

      // Tạo đối tượng File từ Blob
      const file = new File([blob], filename);

      // Chuyển đổi Blob thành Base64
      const base64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });

      // Kết thúc loading
      dispatch(blogsAction.setLoading(false));
      return { file, url: base64 };
    } catch (error) {
      // Xử lý lỗi
      console.error("Có lỗi xảy ra:", error);
      dispatch(blogsAction.setLoading(false)); // Kết thúc loading ngay cả khi có lỗi
      throw error; // Ném lại lỗi để xử lý ở nơi khác nếu cần
    }
  };

  useEffect(() => {
    if (blogContent) {
      setValue("title", blogContent?.title);
      setValue("content", blogContent?.content);
      setValue("en_title", blogContent?.en_title);
      setValue("en_content", blogContent?.en_content);
      if (blogContent.banner) {
        console.log(blogContent.banner);
        urlToFileAndBase64(
          IMAGE_URL_ROOT + blogContent?.banner?.replace("static/images/", ""),
          blogContent?.banner?.replace("static/images/", "")
        ).then((item) => {
          setBannerFile(item);
          setValue("image", [item]);
        });
      }
    }
  }, [blogContent]);
  const editBlog = async () => {
    await trigger(undefined, { shouldFocus: false });
    if (isValid) {
      const data = getValues();
      const toastId = toast.loading("Đang chỉnh sửa bài...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      adminApi
        .updateBlog(blogContent.id, data)
        .then((res) => {
          if (res.status === 200 || res.status_code === 200) {
            navigate("/admin/blogs");
            toast.update(toastId, {
              render: "Chỉnh sửa bài thành công",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
          } else {
            toast.update(toastId, {
              render:
                "Đã xảy ra lỗi trong lúc chỉnh sửa bài, vui lòng thử lại sau",
              type: "error",
              isLoading: false,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          toast.update(toastId, {
            // render: "Đã xảy ra lỗi trong lúc đăng bài, vui lòng thử lại sau",
            render: err,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      alignItems="center"
    >
      <div className=" p-10 w-full max-w-[1200px]">
        <Box display="flex" flexDirection="column" width="100%">
          <div className="font-bold text-3xl text-primary">
            Chỉnh sửa bài viết
          </div>
          <Divider sx={{ my: 1 }} />
          <>
            <Box
              className="bg-blue-200 bg-opacity-30 my-5"
              display="flex"
              flexDirection="column"
              width="100%"
              p={3}
              borderRadius="10px"
            >
              <BlogForm
                bannerFile={bannerFile}
                content={blogContent?.content}
                en_content={blogContent?.en_content}
                noEdit={false}
                control={control}
                errors={errors}
                setValue={setValue}
                uploadImageUrl="/posts/upload-content-images"
                setFocus={setFocus}
              />
            </Box>
          </>

          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            gap={2}
            p={1}
          >
            <Button
              variant="outlined"
              className="bg-primary"
              onClick={() => {
                navigate(`/admin/blogs`);
              }}
              sx={{
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              <Typography className="font-bold">Huỷ bỏ</Typography>
            </Button>

            <Button
              variant="contained"
              className="bg-primary"
              onClick={() => editBlog()}
              sx={{
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              <Typography className="font-bold">Cập nhật bài</Typography>
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  );
};
