import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Editor from "ckeditor5-custom-build";
// import Editor from "ckeditor5-custom-build/build/ckeditor";
// import DefaultEditor from "@ckeditor/ckeditor5-build-classic";
// import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useEffect, useRef, useState } from "react";

// mui
import { Backdrop, CircularProgress } from "@mui/material";
import { adminApi } from "pages/Admin/adminsApi";
import { API_URL_ROOT } from "utils/constant";
import { CustomUploader } from "./CustomUpload";

import {
  ClassicEditor,
  AccessibilityHelp,
  Autoformat,
  AutoImage,
  Autosave,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  CloudServices,
  Essentials,
  Heading,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  SelectAll,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  Undo,
  FontFamily,
  FontSize,
  FontBackgroundColor,
  FontColor,
  Alignment,
} from "ckeditor5";

import translations from "ckeditor5/translations/vi.js";

import "ckeditor5/ckeditor5.css";
import "./index.css";
export const TextEditor = ({
  propRef,
  initialData,
  onChange,
  noEdit = false,
  readonly,
  onReady,
  onError,
  uploadImageRequestHeader,
  uploadImageRequestUrl,
  post_id,
}) => {
  //   if (uploadImageRequestUrl === "")
  //     return <p>Please provide image request url!</p>;
  const editorContainerRef = useRef(null);
  const editorMenuBarRef = useRef(null);
  const editorToolbarRef = useRef(null);
  const editorRef = useRef(null);
  const editorMinimapRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const editorConfig = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "fontFamily",
        "fontSize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "|",
        "alignment",
        "link",
        "insertImage",
        "mediaEmbed",
        "insertTable",
        "blockQuote",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
    },
    plugins: [
      AccessibilityHelp,
      Autoformat,
      Alignment,
      AutoImage,
      Autosave,
      Base64UploadAdapter,
      BlockQuote,
      Bold,
      FontBackgroundColor,
      FontColor,
      CloudServices,
      Essentials,
      Heading,
      FontFamily,
      FontSize,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      MediaEmbed,
      Paragraph,
      PasteFromOffice,
      SelectAll,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      Underline,
      Undo,
    ],
    translations: [translations],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    image: {
      toolbar: [
        "toggleImageCaption",
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    placeholder: "Nhập hoặc dán nội dung của bạn vào đây!",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
  };
  return (
    <div>
      <div className="main-container">
        <div
          className="editor-container editor-container_classic-editor"
          ref={editorContainerRef}
        >
          <div className="editor-container__editor">
            <div ref={editorRef}>
              {isLayoutReady && (
                <CKEditor
                  disabled={noEdit}
                  data={initialData}
                  editor={ClassicEditor}
                  config={editorConfig}
                  onReady={(editor) => {
                    if (readonly)
                      editor.ui.view.toolbar.element.style.display = "none";
                    if (typeof onReady !== "undefined") onReady();
                    editor.plugins.get("FileRepository").createUploadAdapter = (
                      loader
                    ) => {
                      return new CustomUploader(
                        loader,
                        uploadImageRequestUrl,
                        post_id
                      );
                    };
                    editor.model.document.on("change:data", () => {
                      const changes = editor.model.document.differ.getChanges();
                      changes.forEach((change) => {
                        if (change.type === "remove") {
                          if (change.name === "imageBlock") {
                            console.log("change", change);
                            const deletedList = change.attributes;
                            deletedList.forEach((value, key) => {
                              if (key === "src") {
                                const image_name = value.replace(
                                  API_URL_ROOT + "/StaticFolder/posts/",
                                  ""
                                );
                                adminApi
                                  .deleteBlogImage(image_name)
                                  .then((res) => {
                                    console.log("Image removed", res);
                                  });
                              }
                            });
                          }
                        }
                      });
                    });
                    propRef.current = editor;
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ data });
                    if (onChange) {
                      console.log(data);
                      onChange(data);
                    }
                  }}
                  onError={(error, details) => {
                    if (typeof onError !== "undefined") onError();
                    console.log({ error, details });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
